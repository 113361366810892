import { css } from "styled-components"

import { bustCache } from "./image"

export const color = {
  white: "#fff",
  black: "#000",
  inkBlack: "#3c3c3c",
  steelGray: "#535353",
  dimGray: "#676767",
  gray: "#727272",
  orchidGray: "#b9b9b9",
  silverGray: "#9e9e9e",
  lightGray: "#d8d8d8",
  whiteGray: "#eee",
  choke: "#e9e9e9",
  whiteSmoke: "#f6f6f6",
  ivory: "#fefbea",
  creamYellow: "#fcf3c0",
  yellow: "#f8d92d",
  topaz: "#f5ce21",
  cadmiumGreen: "#58b435",
  blue: "#0574eb",
  lightBlue: "#37AEEF",
  purple: "#5505eb",
  darkRed: "#ef3743",
  gold: "#e2b647",
  goldenrod: "#c89f62",
  orange: "#EB5505",
  bgMain: "#f1f1f1",
  shadow: "#bbb6b6"
}

export const thumbnailSize = 80
export const thumbnailSizePaid = 60

export const mixin = {
  maxWidth: css`
    max-width: 414px;
  `,
  borderRadius: css`
    border-radius: 4px;
  `,
  buttonWidth: css`
    width: 290px;
  `,
  thumbnail: css`
    width: ${thumbnailSize}px;
    height: ${thumbnailSize}px;
  `,
  shadow: css`
    box-shadow: 0 5px 7px -7px rgba(0, 0, 0, 0.45);
    margin-bottom: 8px;
  `
}
export const url = (path: string) => {
  return () => `url(${bustCache(path)})`
}
