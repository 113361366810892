import getConfig from "next/config"

const assetsPath = "/assets/img"

export function buildAssetsPath(type: "banner" | "thumbnail") {
  return function (file: string | number, component = "", assetsPathCustom = "", ext = "png") {
    const prefixComponent = component ? component + "-" : ""
    const prefixAssetsPath = assetsPathCustom ? assetsPathCustom : ""
    return `${prefixAssetsPath}${assetsPath}/${type}/${prefixComponent}${file}.${ext}`
  }
}

const imageSizeMap = {
  banner: {
    w: "750",
    h: "340"
  },
  thumbnail: {
    w: "160",
    h: "160"
  }
}

export function adjustImgixParam(type: "banner" | "thumbnail") {
  const param = {
    auto: "format,compress",
    w: imageSizeMap[type].w,
    h: imageSizeMap[type].h
  }

  return function (url: string) {
    const isPsd = url.match(/\.psd$/)
    if (isPsd) {
      const query = Object.entries(param)
        .map((v) => `${v[0]}=${v[1]}`)
        .join("&")
      return `${url}?${query}`
    }
    return url
  }
}

export function bustCache(path: string) {
  const { buildTimestamp } = getConfig().publicRuntimeConfig
  return `${path}?cc=${buildTimestamp}`
}
